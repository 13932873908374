.home-page {
  a.youtube {
  }
  img {
    margin-bottom: 1rem;
    border: 2px solid black;
  }
  .card {
    margin: 1rem;
  }

  .home-tab-content {
    margin-top: 2rem;
  }
  #home-tabs-container {
    margin-top: 3rem;
  }

  .nav-link {
    color: #000 !important;
    border: none;
  }

  .nav-link.active {
    color: #fff !important;
  }

  a.nav-link {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  .icon-background {
    color: rgb(24, 39, 54);
  }

  .icon-foreground {
    color: #fff;
  }

  .icon-subtitle {
    margin-top: 0.5rem;
  }

  .icon-learn-more {
    font-size: small;
  }
}
