/****************** Lightbox ******************/
.loading-overlay {
  position: fixed;
  z-index: 9999;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.78);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .content {
    position: relative;
    margin: 0 auto;
    top: 38%;
    transform: translateY(-50%);
  }

  .loader {
    margin: 0 auto;
    border-top: 5px solid #26b999;
    width: 98px;
    height: 98px;
  }
}

.lightbox {
  position: fixed;
  z-index: -1;
  font-size: 12px;
  background: rgba(0, 0, 0, 0);
}

.lightbox_active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.38);
}

.lightbox2 {
  position: fixed;
  z-index: 2;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.2);

  .report-page,
  .report-page .main {
    background: none;
  }

  .main-active {
    background: #f2f2f2 !important;
  }

  .icon {
    width: 30px;
  }
}

.lightbox2-active {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.lightbox_modal {
  position: relative;
  top: 38%;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 818px;
  /* height: 408px; */
  background: #2e3341;
  padding: 3em;
  color: #182348;
  border-radius: 5px;
  opacity: 0;
}

.lightbox-revision-history {
  padding: 0.5rem;
  min-height: 300px;
  max-height: 400px;
  overflow-y: hidden;

  text-align: left;

  .modal-content {
    overflow-y: hidden;
  }

  .modal-header {
    background: #182736;
  }

  .modal-body {
    padding: 0.25rem;
    height: 300px;
    overflow-y: scroll;
  }

  .close,
  .modal-title {
    color: #fff;
    text-shadow: none;
    opacity: 0.8;
    font-weight: normal;
  }

  .table {
    .table-ytb th {
      font-weight: normal;
    }

    td,
    th {
      border-width: 1px;
      vertical-align: middle;
      font-size: 1rem;
    }

    tr:last-child {
      td,
      th {
        border-bottom: 1px solid #dee2e6;
      }
    }
  }

  sub {
    bottom: 0.5rem;
    color: map-get($colors, 'ytb');
  }

  .username {
    padding: 0;
    margin: 0;
  }

  .date {
    color: gray;
    font-size: 0.9rem;
    margin: 0;
  }
}

.lightbox-export-data {
  padding: 0.5rem;
  max-width: 850px;
  overflow-y: hidden;
  text-align: left;

  .modal-content {
    overflow-y: hidden;
  }

  .modal-body {
    padding: 0.25rem;
    height: 450px;
    font-size: 1.1rem;
  }

  .close {
    text-shadow: none;
    opacity: 0.8;
    font-weight: normal;
  }

  .card-data-exports {
    border: none;

    .card-header,
    .card-footer {
      background-color: #fff;
    }

    .card-body {
      overflow-y: auto;
    }
  }

  .btn:disabled {
    background-color: #d1cfcf;
    border-color: #d1cfcf;
    color: #333;
  }

  h5 {
    color: #606368;
  }

  p {
    font-size: 1rem;
  }

  .form-check {
    margin: 0.5rem 0;
  }

  .form-check-label {
    cursor: pointer;
  }

  .btn-group {
    .btn-outline-secondary {
      font-size: 0.9rem;
      background-color: #f5f5f5;
      color: #252525;
    }

    .btn-outline-secondary:hover {
      background-color: map-get($colors, 'ytb');
      color: #fff;
    }

    .selected {
      background-color: map-get($colors, 'ytb');
      color: #fff;
    }
  }

  .list-books {
    border: 1px solid #afafaf;
    height: 200px;
    overflow-y: scroll;
    padding: 0.5rem;

    h2 {
      font-size: 1rem;
    }

    .row {
      margin: 0;
    }

    .book-name-list {
      border: none;
      font-size: 1rem;
      padding: 0.5rem 0;
      text-align: left;
    }
  }

  .list-chapters {
    min-height: 255px;
  }
}
