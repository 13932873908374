/****************** Password Reset ******************/
.form-password-reset {
  width: 100%;
  padding: 1rem 3rem;
  margin: auto;

  .checkbox {
    font-weight: 400;
  }

  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: map-get($font-size, 'large');
  }

  .form-control:focus {
    z-index: 2;
  }

  a {
    color: map-get($colors, 'a');

    &:hover {
      color: map-get($colors, 'a_hover');
    }
  }

  .form-check {
    padding-left: 0;
  }
}
