.help-html {
  color: rgba(0, 0, 0, 0.75);
  background: #fff;
  font-size: 16px;
  font-family: Lato, Helvetica Neue, Helvetica, sans-serif;
  font-variant-ligatures: common-ligatures;
  line-height: 1;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  letter-spacing: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.8em 0;
    line-height: 1.33;
  }

  h1::after,
  h2::after {
    content: '';
    display: block;
    position: relative;
    top: 0.33em;
    border-bottom: 1px solid hsla(0, 0%, 50%, 0.33);
  }

  h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }

  blockquote,
  dl,
  ol,
  p,
  pre,
  ul {
    margin: 1.2em 0;
    line-height: 1.5;
  }

  li {
    line-height: 2;
  }

  img {
    max-width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  a {
    color: #0c93e4;
  }
}

.help-title {
  top: 0;
  padding: 1rem 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 320px;
  flex: 0 1 320px;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  li {
    display: block;
  }

  ul {
    padding: 0;

    a {
      margin: 0.5rem 0;
      padding: 0.5rem 1rem;
    }

    a:hover {
      background-color: rgba(0, 0, 0, 0.075);
      border-radius: 3px;
    }

    ul {
      color: #888;
      font-size: 0.9em;
      padding-left: 1rem;

      li {
        line-height: 1;
      }

      ul {
        padding-left: 2rem;
      }
    }
  }

  a {
    display: block;
    color: inherit;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
  }
}

$help-page-nav-width: 280px;

.help-left {
  position: sticky;
  left: 0;
  top: 0;
  height: calc(100vh - #{$ytb-main-nav-height});
  width: $help-page-nav-width;
}

.help-right {
  order: 1;
  -ms-flex-order: 1;
  background: #fff;

  overflow-y: scroll;
  height: calc(100vh - #{$ytb-main-nav-height});
  width: calc(100% - #{$help-page-nav-width});
}

.help-doc {
  max-width: 800px;
  margin: auto;
}
