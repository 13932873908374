/****************** Modal ******************/
.modal_dialog {
  position: relative;
  top: 40%;
  transform: translateY(-50%);
  margin: 0 auto;

  /* height: 449px; */
  background: transparent;
  padding: 0;
  color: #5f6562;
  /* border-radius: 5px; */
  display: none;
}

.modal-dialog-small {
  width: 580px;
  max-width: 90%;
  top: 50%;
}

.modal_dialog_regular {
  width: 800px;
  min-width: 900px;
  max-width: 90%;
  max-height: 80%;
}

.modal-dialog-login {
  width: 399px;
  max-width: 90%;
  max-height: 80%;
  top: 40%;
}

.modal_dialog_active {
  opacity: 1;
  display: block;

  /* transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px); */
}

.modal-body-controll {
  padding: 1rem;
}

.lightbox_active {
  background: rgba(0, 0, 0, 0.7);

  .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
  }
}

.lightbox_modal h2 {
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 1px;
}

.header {
  padding-bottom: 1em;
}

.shadow {
  background: #0000001a;
  padding: 6px;
  margin: 1em 0;
}

.modal-header,
.modal-footer {
  color: #000;
  font-size: 1rem;
  background: linear-gradient(
    to bottom,
    rgb(234, 234, 234) 0%,
    rgb(234, 234, 236) 50%,
    rgb(231, 231, 231) 100%
  );
}

.modal-header {
  border-bottom: 1px #ccc solid;
  padding: 1rem;
}

.modal-footer {
  padding: 0.25rem 1rem;
}

.modal-header-black {
  border-bottom: 1px #ccc solid;
  background: #182736;
  color: #fff;
  border-radius: 0;

  .close {
    padding: 5px;
    margin: 0;
    opacity: 0.8;
  }

  .close:hover {
    padding: 5px;
    margin: 0;
    opacity: 1;
  }
}

.modal-title {
  font-size: 0.8rem;
  color: #4f5560;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  line-height: 2;
}

.modal-body {
  font-size: map-get($font-size, 'large');
  height: 100%;
}

.modal-login .modal-header,
.modal-login .modal-footer {
  border-bottom: none;
  position: relative;
  justify-content: center;
  background: none;
}

.modal-login {
  .modal-title {
    text-align: center;
    font-size: 28px;
    color: #696969;
    width: 100%;
    padding-top: 1rem;
  }

  .close {
    cursor: pointer;
    position: absolute;
    right: 11px;
  }
}
