.loader {
  color: #dce0e3;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &,
  &:before,
  &:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
    -webkit-animation: loading 1.8s infinite ease-in-out;
    animation: loading 1.8s infinite ease-in-out;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &:after {
    left: 3.5em;
  }
}

@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@-webkit-keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.loader2 {
  border: 5px solid #e7e7e7;
  border-radius: 50%;
  border-top: 4px solid #535353;
  width: 58px;
  height: 58px;
  animation: spin 0.8s linear infinite;
  -webkit-animation: spin 0.8s linear infinite;
}

.cover-parent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.cover-parent div:first-child {
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.greybox {
  background: #ffffff80;
}

.darkbox {
  background: rgba(0, 0, 0, 0.5);
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: #f0f0f069;
  display: block;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  span {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #dadada;
    animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  span:nth-child(1) {
    left: 8px;
    animation-delay: -0.2s;
  }

  span:nth-child(2) {
    left: 32px;
    animation-delay: -0.1s;
  }

  span:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }

  @keyframes spinner {
    0% {
      top: 8px;
      height: 64px;
    }

    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
}
