$font-size: (
  'regular': 14px,
  'large': 16px,
);

$colors: (
  'a': #379ef4,
  'a_hover': #2b8edf,
  'ytb': #a4c618,
  'ytb_hover': #bdd656,
  'ytb_disabled': #d7d7d7,
  'facebook': #3c66c4,
  'facebook_hover': rgb(45, 90, 187),
  'google': #cf4332,
  'google_hover': rgb(197, 58, 42),
  'box_header_bg': #182736,
  'box_header_color': #fff,
  'suggestion-text': #2aa4ea,
  'manuscript-bg': #f2f2e0,
  'manuscript-highlight': #e3e2c2,
  'btn-primary': #3863b7,
  'shared': #7edcf5,
);

$ytb-font-family: 'Roboto';
$ytb-font-family-style: sans-serif;
$ytb-main-nav-height: 84px;

html,
body {
  overflow-x: hidden;
  padding-top: 42px;
  background-color: #f7f9fb;
  font-family: $ytb-font-family, $ytb-font-family-style;
  font-size: map-get($font-size, 'regular') !important;
  min-height: 100%;
}

pre {
  width: 100%;
  font-family: $ytb-font-family, $ytb-font-family-style;
  font-size: 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;
}

pre {
  width: 100%;
  font-family: $ytb-font-family, $ytb-font-family-style;
  font-size: 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;
}

@media (max-width: 991.98px) {
  .offcanvas-collapse {
    position: fixed;
    top: 56px;
    bottom: 0;
    left: 100%;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: #343a40;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: left, visibility;
  }

  .offcanvas-collapse.open {
    left: 0;
    visibility: visible;
  }
}

.spinnerTag {
  display: -webkit-flex !important;
  z-index: 9999 !important;
  -webkit-align-items: center;
  align-items: center;
}

.navbar {
  padding: 1.5rem 2rem;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.nav-underline .nav-link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  color: #6c757d;
}

.nav-underline .nav-link:hover {
  color: #007bff;
}

.nav-underline .active {
  font-weight: 500;
  color: #343a40;
}

.nav-item {
  padding-right: calc(1rem + 1vw);
  padding-left: calc(1rem + 1vw);
  justify-content: center;
}

.nav-link {
  color: #fff !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: #182736 2px solid;
}

.nav-link,
.nav-item .btn {
  font-size: map-get($font-size, 'large') !important;
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  border-bottom: #fff 2px solid;
}

@media (min-width: 768px) {
  .container {
    padding-top: 3rem;
  }
}

.text-ytb {
  color: map-get($colors, 'ytb') !important;
}

.text-error {
  color: red;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5);
}

.bg-dark {
  background-color: #182736 !important;
}

.bg-purple {
  background-color: #6f42c1;
}

.lh-100 {
  line-height: 1;
}

.lh-125 {
  line-height: 1.25;
}

.lh-150 {
  line-height: 1.5;
}

.svgLine {
  stroke: #333;
  cursor: pointer;
}

.svgLine:hover {
  stroke: #da4567;
  filter: drop-shadow(3px 4px 4px rgb(0, 0, 0));
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  display: none;
}

.overlay-active {
  z-index: 9999;
  background: rgba(0, 0, 0, 0.27);
  display: block;
}

.overlayer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.5);
}

.short-animate {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.middle-animate {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.long-animate {
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -ms-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}

.form-group {
  margin-bottom: 8px;
}

.required-field::after {
  content: '\002A';
  margin-left: 2px;
  color: rgb(133, 10, 10);
}

.large-checkbox {
  transform: scale(1.2);
  cursor: pointer;
}

.toolbar {
  background: #eaeaea;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(229, 229, 229, 1) 100%);
  box-shadow: 0px 4px 9px -10px #000000, 13px -50px 7px 7px rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0px 4px 9px -10px #000000, 13px -50px 7px 7px rgba(0, 0, 0, 0);
}

.short-animate {
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.middle-animate {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.long-animate {
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -ms-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: 1s ease-in-out;
}

.scripture-styles {
  font-family: $ytb-font-family, $ytb-font-family-style !important;
  font-size: map-get($font-size, 'regular') !important;

  .reference-text {
    background: map-get($colors, 'manuscript-bg');
    color: #4b4b49;
    padding: 0.5rem;

    .s,
    .s1 {
      font-size: 1rem;
      line-height: 150%;
      margin-bottom: 0.5rem;
    }

    p[class^='q'] {
      font-size: 1rem;
      text-align: left;
      word-break: break-word;
      margin: 0;
      text-indent: 0;
    }
  }
}

.modal-message {
  .modal-header,
  .modal-footer {
    background: none;
  }

  .modal-body {
    min-height: 119px;
  }
}

.book-selected {
  background: #ececec;

  .btn {
    cursor: auto;
  }
}

.alert {
  font-size: 1rem;
  font-weight: lighter;
}

.d-content {
  display: content !important;
}

@import './lightbox.scss';
@import './modal.scss';
@import './signin.scss';
@import './passwordReset.scss';
@import './buttons.scss';
@import './project.scss';
@import './loader.scss';
@import './translation.scss';
@import './alignment.scss';
@import './grid.scss';
@import './morphology.scss';
@import './help.scss';
@import './profile.scss';
@import './contact.scss';
@import './home.scss';
@import './table.scss';
@import './selectInterlinear.scss';
@import './dictionary.scss';
@import './verseDirection.scss'
