.morphology-list {
  width: 100%;
  background: map-get($colors, 'manuscript-bg');
}

.morph-verse {
  width: 100%;
  table-layout: fixed;
}
.source-morph-cell {
  padding: 0.4rem;
  text-align: right;
  width: 50%;
}

.gloss-morph-cell {
  padding: 0.4rem;
  text-align: left;
  width: 50%;
}

.verse-group-header {
  text-align: center;
}
