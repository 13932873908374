/****************** alignment ******************/
.left-modal-panel {
  background-color: #eaeaec;
  width: 2rem;
  height: 28rem;
  float: left;
  position: relative;
  margin-right: 0.5rem;
  margin-left: -0.8rem;
  margin-top: -0.9rem;
  margin-bottom: -5rem;
}
.inside-modal-top {
  position: absolute;
  top: 2.6rem;
  margin-left: 0.75rem;
}
.inside-modal-bottom {
  position: absolute;
  bottom: 4.5rem;
  margin-left: 0.75rem;
}
.links-container {
  /** float:left; */
  max-width: 1000%;
  height: 28rem;
  overflow-x: scroll;
  overflow-y: hidden;

  .source-container {
    text-align: center;
    width: inherit;
    white-space: nowrap;
    margin-bottom: -2rem;
  }

  .target-container.unlinked {
    margin-top: -0.5rem;
    margin-bottom: 7rem;
  }

  .target-container.unlinked:not(.reverse) {
    margin-top: 1.5rem;
  }

  .target-container.linked:not(.reverse) {
    margin-top: -15rem;
  }

  .no-links-exist {
    text-align: center;
    margin-top: 0rem;
    margin-bottom: 7.1rem;

    .text-segment {
      font-size: large;
      color: white;
      background: red !important;
    }
  }

  .target-container {
    margin-top: -17rem;
    margin-bottom: 16rem;
    text-align: center;
    white-space: nowrap;

    .btn-link {
      color: #333;
    }

    .btn.disabled,
    .btn:disabled {
      opacity: 1;
    }

    .dropdown-menu {
      min-width: auto;

      .dropdown-item {
        padding: 0.25rem 1rem;

        & > span {
          width: 100%;
        }
      }
    }

    .dropdown-toggle::after {
      color: map-get($colors, 'suggestion-text');
    }
  }

  .text-segment {
    display: inline;
    position: relative;
    border-radius: 5px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding: 0.25rem;
    border: 2px solid transparent;
    cursor: pointer;
    z-index: 500;
  }

  .text-segment.not-linkable {
    background-color: white !important;
    cursor: default;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  .text-segment.not-linkable:hover {
    border: 2px solid transparent;
  }

  .text-segment:hover {
    border: 2px solid #000000;
    border-style: solid;
  }

  .text-segment.default {
    background-color: #c8c8c8;
  }

  .text-segment.blue {
    background-color: PowderBlue;
  }

  .text-segment.green {
    background-color: LightSeaGreen;
  }

  .text-segment.orange {
    background-color: orange;
  }

  .text-segment.default.not-linked {
    background-color: #f0f0f0;
  }

  .text-segment.selected {
    background-color: black !important;
    color: white;
  }

  .text-segment.disabled {
    cursor: not-allowed;
  }

  .text-segment.disabled:hover {
  }

  .target-container:focus {
    outline: none !important;
  }

  .source-container:focus {
    outline: none !important;
  }

  .links-container:focus {
    outline: none !important;
  }
}

.links-canvas {
  pointer-events: none;
  position: relative;
  width: inherit;
  height: 80%;
  margin-bottom: -5rem;
  z-index: 10;
  width: 800%;
}

.links-canvas.reverse {
  margin-top: 0.5rem;
  margin-bottom: -5.5rem;
}

.link-line {
  z-index: 5;
  pointer-events: visible;
  cursor: pointer;
}

.link-line.disabled {
  cursor: not-allowed;
}

.link-line:hover {
  border: 2px solid #000000;
}

.alignment-spinner {
  margin: 3rem;
}

.alignment-modal-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.alignment-modal-mode {
  font-size: medium;
  color: #007bff;

  i.fas {
    font-size: x-large;
  }
}

.verify {
  align-self: flex-end;
  margin-top: -2.2rem;

  i {
    font-size: xx-large;
    cursor: pointer;
  }

  i.fa-reply {
    color: #007bff;
  }

  i.fa-check {
    color: LightSeaGreen;
  }

  button.btn-link {
    background-color: transparent;
    border: none;

    &:disabled {
      i {
        color: #ccc;
      }
    }
  }
}

.alignment-reference {
  display: inline;
  margin-right: 0.35rem;
}

.modal-title {
  align-self: flex-start;
}

.alignment-action {
  margin-top: 0.5rem;
  margin-left: 2rem;
  cursor: pointer;
  color: #007bff;
}

.alignment-action.message {
  margin-left: 15rem;
  cursor: unset;
  color: black;
  font-size: medium;
  align-self: center;
}

.alignment-action.disabled {
  cursor: not-allowed;
  color: #c8c8c8;
}

.modal-full {
  width: 95%;
  max-width: unset;
}

.header-container {
  width: 100%;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.enriched-data {
  font-size: small;
  padding: 1rem;
  cursor: pointer;
  z-index: 5000;
}
