.dictionary-card {
  overflow-y: hidden !important;
  width: 100%;
  position: relative;
  padding: 0 !important;
  background: map-get($colors, 'manuscript-bg');

  .rtl {
    direction: rtl;
  }

  .show {
    height: auto !important;
  }

  .collapse:not(.show) {
    display: none !important;
  }

  .dict-container {
    height: 100%;
  }

  .dict-help {
    margin-top: 2rem;
    color: #5e5e5e;

    p {
      margin-left: 0.5rem;
    }
  }

  h2 {
    font-size: 1.2rem;
    padding-bottom: 0.5rem;
  }

  h3 {
    font-size: 1rem;
  }

  .search-box {
    // top: 0;
    // position: sticky;
    z-index: 999;
    order: 1;
    width: 100%;
    background: #dadada;
    padding: 0.5rem;
    height: 51px;
    -webkit-box-shadow: 0px -1px 5px 1px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 0px -1px 5px 1px rgba(0, 0, 0, 0.28);
    box-shadow: 0px -1px 5px 1px rgba(0, 0, 0, 0.28);
  }

  table {
    width: 100% !important;
  }

  .dict-toolbar {
    text-align: right;
    margin-top: 10px;
  }

  .btn-tool {
    padding: 0;
    width: 25px;
    height: 25px;

    &:hover {
      color: map-get($colors, 'suggestion-text');
    }

    i {
      transform: rotate(0deg) !important;
    }
  }

  .btn-tool-selected {
    color: map-get($colors, 'suggestion-text');
  }

  .dict-content {
    order: 2;
    width: 100%;
    padding-right: 20px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
    overflow-y: auto;
  }

  .dictionary-search-input {
    padding: 0.5rem 2rem;
    border-radius: 1rem;
    height: calc(1.5em + 0.75rem + 2px);
  }

  .submit-btn {
    float: left;
    margin-left: 5px;
    position: absolute;
    width: 2rem;
    text-align: center;
    transform: scaleX(-1);
    color: #0670f8a6;

    &:hover {
      color: #0670f8;
    }

    &:disabled {
      color: #333333;
    }
  }

  .clear-btn {
    float: right;
    margin-top: -33px;
    position: relative;
    width: 2rem;
    text-align: center;
    color: #0670f8a6;
    margin-right: 5px;

    &:hover {
      color: #0670f8;
    }
  }

  .dict-list {
    margin-top: 1rem;

    .sub-title {
      font-size: 1.1rem;
      padding-right: 0.5rem;

      .link {
        display: inline-block;
        text-align: left;
        color: #000;
        cursor: pointer;

        :hover {
          color: map-get($colors, 'suggestion-text');
        }
      }
    }

    .manuscript {
      font-size: 1rem;
    }

    .english,
    .strongs {
      font-size: 1rem;
      font-weight: 300;
      margin-left: 0.25rem;
    }

    .translation {
      font-size: 1rem;
      font-weight: normal;
      margin-left: 0.25rem;
    }

    .occurrence {
      float: right;
      font-size: 1rem;
      font-weight: 300;
      margin-top: 0.5rem;
    }

    h5 {
      font-size: 1.1rem;
      padding-bottom: 0.5rem;

      .manuscript,
      .english,
      .strongs {
        font-size: 1.1rem;

        &:hover {
          color: map-get($colors, 'suggestion-text');
        }
      }
    }

    .sub-menu {
      display: block;
      font-weight: lighter;
      max-height: 500px;
      overflow-y: auto;
      width: 100%;

      b {
        color: map-get($colors, 'suggestion-text');
        font-weight: normal;
      }

      sub {
        bottom: 0.25em;
      }

      .list-group-item {
        min-height: 100px;
      }
    }
  }
}
