.switch-direction {
  display: inline;
  float: right;
  right: 15px;
  top: -3px;

  .dropdown-menu {
    margin-left: -18rem !important;
  }

  span.item-title {
    font-size: 1.2rem;
  }

  p.item-description {
    font-size: 0.8rem;
    margin: 0px;
    white-space: initial;
  }

  p.license-alignment {
    margin-top: 0.5rem;
  }

  a.selected {
    background: #eee;
  }
}
